<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：货品管理主页面
	开始时间：2021-01-14
	开发人员：刘巍骏,万攀
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <!-- 货品管理 -->
  <div class="AppTreeTableContent" id="product_body">
    <!-- 资料左边列表 -->
    <div class="AppLeftBox">
      <!-- 商品分类 -->
      <div class="content_left">
        <!-- 产品分类列表 -->
        <div class="left_list">
          <div class="left_all_btn" :class="{'left_all_btn_sel':selClassifyId == ''}" @click="getClassifyData(0)">
            <div>
              <i class="all_icon el-icon-menu"></i>
              <span>全部分类</span>
            </div>
          </div>
          <div>
            <el-tree :data="ClassfiyData" :props="defaultProps" node-key="id" ref="tree" default-expand-all
              :expand-on-click-node="false" @current-change="getClassifyData">
              <span class="custom-tree-node" slot-scope="{ node, ClassfiyData}">
                <span>{{ node.label }}</span>
                <span>
                  <el-button type="text" size="mini">
                    <i class="el-icon-more"></i>
                  </el-button>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
    </div>

    <!-- 资料分类拉伸 -->
    <div class="AppHandelBox"></div>

    <!-- 资料操作内容 -->
    <div class="AppRightBox">
      <commodity-view ref="commodityIndex" :selClassifyId="selClassifyId" :is_execute="is_execute"></commodity-view>
    </div>
  </div>
</template>

<script>
  //导入请求
  import api from '@/network/system/dictionary'
  import req from '@/network/datum/product'

  //导入组件
  import CommodityView from './commodity/commodity_view.vue'

  export default {
    name: 'commodityIndex',
    data() {
      return {
        //页面数据
        ClassfiyData: [], //产品分类数据
        selClassifyId: "", //当前选择的产品分类数据

        //改变tree属性名
        defaultProps: {
          children: 'children',
          label: 'name'
        },

        // 辅助数据
        is_execute: false, //是否执行查询(用于每次点击时都执行一次查询)

        // loading框
        loading: '', //loading框加载
      }
    },
    created() {
      //获取产品分类列表
      this.getProdoctClassfiy();
    },
    mounted() {
      // 初始化分类栏目拉伸
      this.commonJsExtend.getDunamicWidth();
    },
    methods: {
      /* 查询货品分类列表 */
      getProdoctClassfiy() {
        //发送请求
        api.productDict.getProductClassfiy().then(res => {
          if (!!res) {
            this.ClassfiyData = res.data;
          }
        })
      },

      /* 获取当前分类数据 */
      getClassifyData(data) {
        //只要发生该时间就进行一次查询
        this.is_execute = !this.is_execute;
        //判断查询类型
        if (data == 0) {
          this.selClassifyId = "";
        } else {
          let id;
          id=data.id
          if(!!data.children){
              data.children.forEach((item,index)=>{
                id=id+","+item.id;
                if(!!item.children){
                  item.children.forEach((itemI,indexI)=>{
                    id=id+","+itemI.id
                  })
                }
              })
          }else{
            id=id+""
          }
          this.selClassifyId = id;

        }
      },

      /* 修改分类为全部 */
      updateClassifyToAll(){
        this.selClassifyId = "";
      },
    },
    components: {
      CommodityView,
    }
  }
</script>

<style lang="less">
  .AppTreeTableContent {
    height: 100%;
    width: 100%;
    display: flex;
    // border: 1px solid black;
  }
</style>

<!-- 导入单据公共样式 -->
<style lang="less" src="@/less/Comm/comm_billing.less"></style>
